import React, { useEffect, useState, useMemo, useContext } from 'react';
import Layout from 'components/Layout';
import PropTypes from 'prop-types'
import Button from 'components/Button';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar'
import classes from "./EventCalendar.module.css";
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import fetcher from 'util/fetcher';
import { ModalContext } from 'constants/Contexts';
import DetailsPopup from './EventCalendar/DetailsPopup';
import EventTypeFilter from './EventCalendar/EventTypeFilter';
import Loader from 'components/Loader';
import CustomEventPopup from './EventCalendar/CustomEventPopup'

function EventCalendar() {
    const [eventData, setEventData] = useState([]);
    const [events, setEvents] = useState(null);
    const [eventFilter, setEventFilter] = useState(["GAME_EVENT", "JSON_CHANGE"]);
    const [gameFilter, setGameFilter] = useState("wwe");
    const localizer = momentLocalizer(moment);
    const ColoredDateCellWrapper = ({ children }) =>
        React.cloneElement(React.Children.only(children), {
            style: {
            },
        });
    const { modalContent, setModalContent } = useContext(ModalContext);

    async function fetchInfo() {
        const response = await fetcher(`https://webportal.cdgsrv.com/EventTimeline/GetTimeline`, { method: 'POST' })
        const data = await response.json();
        setEventData(data);
        initEvents(data)
    }
    function eventStyleGetter(event, start, end, isSelected) {
        var backgroundColor = 'lightblue';
        switch (event.type) {
            case "GAME_EVENT":
                backgroundColor = '#fe887c'; //red
                break;
            case "BUILD":
                backgroundColor = '#5483eb'; //blue
                break;
            case "JSON_CHANGE":
                backgroundColor = '#fdd75c'; //yellow
                break;
            case "PUSH":
                backgroundColor = '#c1e29e'; //green
                break;
            case "MAINTENANCE":
                backgroundColor = "#7dbdb6"
                break;
            case "GAME_BULLET":
                backgroundColor = "#d59546"
                break;
            case "TWITCH_DROPS":
                backgroundColor = "#db6787"
                break;
            case "CUSTOM":
                backgroundColor = "#8667a8"
                break;
        }
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            color: "black",
            opacity: 1,
            border: "1px solid black",
            display: 'block'
        };
        return {
            style: style
        };
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    function updateFilter(status, name) {
        var oldFilter = eventFilter;
        var expire = addDays(Date.now(), 365);
        if (status) {
            oldFilter.push(name);
        }
        else {
            for (var i = 0; i < oldFilter.length; ++i) {
                if (oldFilter[i] == name) {
                    oldFilter.splice(i, 1);
                }
            }
        }
        setEventFilter(oldFilter);
        var filterString = oldFilter.join(',');
        document.cookie = `filter=${filterString};expires=${expire.toUTCString()};`;
        filterEvents(eventData, oldFilter, gameFilter);
    }
    function updateGameFilter(status, name) {
        var game = "";
        var expire = addDays(Date.now(), 365);
        if (status) {
            game = name;
        }
        setGameFilter(game);
        document.cookie = `game=${game};expires=${expire.toUTCString()};`;
        filterEvents(eventData, eventFilter, game);
        
    }
    function filterEvents(data, filter, game) {
        var temp = [];
        data.forEach((item, index) => {
            if (item.Game && item.Server) {
                var allDayEvent = false;
                if (moment(item.StartTime).day === moment(item.EndTime).day && !item.EndTime.includes("9999")) {
                    allDayEvent = true;
                }
                if (item.Details.includes("Maintenance.MaintenanceMode")) {
                    item.EventType = "MAINTENANCE"
                }
                if (filter.length === 0 && !game) {
                    temp.push({
                        start: moment(item.StartTime).toDate(),
                        allDay: allDayEvent,
                        end: item.EndTime.includes("9999") ? moment(item.StartTime).add(30, 'minutes').toDate() : moment(item.EndTime).toDate(), //events need an end time in order to show up on the calendar and it needs to be at least 30 min from start time to avoid visual bugs on the calendar.
                        title: item.EventType + ' ' + item.EventName,
                        type: item.EventType,
                        data: item,
                        realEndTime: item.EndTime.includes("9999") ? "N/A" : moment(item.EndTime).toDate()
                    });
                }
                else {
                    if (filter.length > 0 && game) {
                        for (var i = 0; i < filter.length; ++i) {
                            if (item.Game.toLowerCase().includes(game) && item.EventType === filter[i]) {
                                temp.push({
                                    start: moment(item.StartTime).toDate(),
                                    allDay: allDayEvent,
                                    end: item.EndTime.includes("9999") ? moment(item.StartTime).add(30, 'minutes').toDate() : moment(item.EndTime).toDate(),//events need an end time in order to show up on the calendar and it needs to be at least 30 min from start time to avoid visual bugs on the calendar.
                                    title: item.EventType + ' ' + item.EventName,
                                    type: item.EventType,
                                    data: item,
                                    realEndTime: item.EndTime.includes("9999") ? "N/A" : moment(item.EndTime).toDate()
                                });
                                break;
                            }
                        }
                    }
                    else if (filter.length === 0 && game) {
                        if (item.Game.toLowerCase().includes(game)) {
                            temp.push({
                                start: moment(item.StartTime).toDate(),
                                allDay: allDayEvent,
                                end: item.EndTime.includes("9999") ? moment(item.StartTime).add(30, 'minutes').toDate() : moment(item.EndTime).toDate(),//events need an end time in order to show up on the calendar and it needs to be at least 30 min from start time to avoid visual bugs on the calendar.
                                title: item.EventType + ' ' + item.EventName,
                                type: item.EventType,
                                data: item,
                                realEndTime: item.EndTime.includes("9999") ? "N/A" : moment(item.EndTime).toDate()
                            });
                        }
                    }
                    else if (!game && filter.length > 0) {
                        for (var i = 0; i < filter.length; ++i) {
                            if (item.EventType === filter[i]) {
                                temp.push({
                                    start: moment(item.StartTime).toDate(),
                                    allDay: allDayEvent,
                                    end: item.EndTime.includes("9999") ? moment(item.StartTime).add(30, 'minutes').toDate() : moment(item.EndTime).toDate(),//events need an end time in order to show up on the calendar and it needs to be at least 30 min from start time to avoid visual bugs on the calendar.
                                    title: item.EventType + ' ' + item.EventName,
                                    type: item.EventType,
                                    data: item,
                                    realEndTime: item.EndTime.includes("9999") ? "N/A" : moment(item.EndTime).toDate()
                                });
                                break;
                            }
                        }
                    }
                }
            }
        });
        setEvents(temp);
        
    }
    function getCookie(name) {
        var cookieName = name + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieList = decodedCookie.split(';');
        for (var i = 0; i < cookieList.length; i++) {
            var cookie = cookieList[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) == 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return null;
    }
    
    function initEvents(data) {
        var gameCookie = getCookie("game");
        var game = gameFilter;
        if (gameCookie !== null) {
            setGameFilter(gameCookie)
            game = gameCookie;
        }
        var eventCookie = getCookie("filter");
        var filter = eventFilter;
        if (eventCookie !== null) {
            filter = eventCookie.split(',');
            setEventFilter(filter);
        }
        filterEvents(data, filter, game);
    }
    const displayDetailsPopup = (event) => {
        setModalContent({
            title: event.title,
            body: <DetailsPopup
                details={event.data.Details}
                eventType={event.type}
                game={event.data.Game}
                server={event.data.Server}
                startTime={event.start}
                endTime={event.realEndTime}
            />
        })
    }
    const displayCustomEventPopup = (event) => {
        setModalContent({
            title: "Custom Event",
            body: <CustomEventPopup

            />
        })
    }
    useEffect(() => {
        fetchInfo();
    }, []);

    const { components, defaultDate, max, views } = useMemo(
        () => ({
            components: {
                timeSlotWrapper: ColoredDateCellWrapper,
            },
            defaultDate: Date.now(),
            views: Object.keys(Views).map((k) => Views[k]),
        }),
        []
    );
    const isLoaded = events!== null;
    return (
        <Layout>
            <div className={classes.root}>
                {isLoaded ? 
                    <div>
                        <h1 className={classes.header}>Cat Daddy Event Calendar</h1>
                        <div className={classes.body}>
                            <Calendar
                                dayLayoutAlgorithm={"no-overlap"}
                                components={components}
                                defaultDate={defaultDate}
                                localizer={localizer}
                                showMultiDayTimes
                                step={60}
                                views={views}
                                events={events}
                                eventPropGetter={eventStyleGetter}
                                onSelectEvent={displayDetailsPopup}
                            />
                        </div>
                        <EventTypeFilter
                            eventFilter={eventFilter}
                            gameFilter={gameFilter}
                            eventCallback={updateFilter}
                            gameCallback={updateGameFilter}
                        />
                        <Button onClick={displayCustomEventPopup} text="Add Custom Event" ></Button>
                    </div> : <Loader />
                }
            </div>
        </Layout>
    )


}
export default EventCalendar;