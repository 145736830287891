import classes from './EventDetail.module.css';

function EventDetail(props) {
    const { detail } = props;
    const { label, value, type = null } = detail;

    return (
        <label className={classes.label}> {label ? `${label}: ` : ""}
            <span className={`${classes.value} ${type === "warning" ? classes.warning : ""}`}>
                {value}
            </span>
        </label>
    );
}

export default EventDetail;