import classes from './WeightEditInput.module.css';
function WeightEditInput(props) {
    const { highlight, weight, setNewData, gameId } = props;

    const { Type, FeaturedPlayer, GameEventDetails } = highlight;

    function findOriginalIndex(highlights) {
        return highlights.findIndex(original => {
            const { Type: originalType, FeaturedPlayer: originalPlayer, GameEventDetails: originalGame } = original;
            //Match on type + name + clock as unique key
            return originalType === Type && FeaturedPlayer?.FullName === originalPlayer?.FullName && GameEventDetails?.clock === originalGame?.clock;
        });
    }
    function updateWeight(newWeight) {
        if (typeof newWeight === "number") {
            setNewData(prev => {
                const gameIndex = prev?.Games?.findIndex(game => game.Id === gameId);
                const highlightIndex = findOriginalIndex(prev?.Games?.[gameIndex]?.Highlights);
                return {
                    ...prev,
                    Games: prev?.Games.map((game, i) => {
                        if (gameIndex === i) return {
                            ...game,
                            Highlights: game?.Highlights.map((old, j) => {
                                if (highlightIndex === j) return { ...old, Weight: newWeight };
                                return old;
                            })
                        };
                        return game;
                    })
                };
            });
        }
    }

    //Not storing state locally, just update parent object when weight is changed
    return (
        <input
            className={classes.root}
            defaultValue={weight}
            onChange={e => updateWeight(parseInt(e.target.value))}
        />
    )
}

export default WeightEditInput;