import getUrlParam from 'util/getUrlParam';

export default function getUrl(manualEnv = null) {
    //TODO: Should handle this once on app initialization, not per call
    const env = getUrlParam("environment");
    const lowerEnv = manualEnv ? manualEnv : env?.toLowerCase() ?? "";
    const game = getUrlParam('game');
    const lowerGame = game?.toLowerCase();

    if (lowerEnv.includes("local")) return "https://localdev.cdgsrv.com:8835";
    else if (lowerGame.includes("phoenix_unreal")) {
        if (lowerEnv.includes("qa")) return "https://bobcatphoenixqa2.cdgsrv.com:8835";
        else if (lowerEnv.includes("wifi")) return "https://phxunrealwifi.cdgsrv.com:8835";
    }
    else if (lowerGame.includes("splash")) {
        if (lowerEnv.includes("wifi")) return "https://nbawifi2.cdgsrv.com:8835";
        else if (lowerEnv.includes("qa")) return "https://bobcatsplashqa.cdgsrv.com:8835";
        else if (lowerEnv.includes("staging")) return "https://bobcatsplashstaging.cdgsrv.com:8835";
        else if (lowerEnv.includes("production")) return "https://bobcatsplashproduction.cdgsrv.com:8835";
    }
    else if (lowerEnv.includes("qa")) return "https://bobcatphoenixqa.cdgsrv.com:8835";
    else if (lowerEnv.includes("staging")) return "https://bobcatphoenixstaging.cdgsrv.com:8835";
    else if (lowerEnv.includes("production")) return "https://bobcatphoenixproduction.cdgsrv.com:8835";
    else if (lowerEnv.includes('wifi')) return "https://phxwifi.cdgsrv.com:8835";
    return "https://localdev.cdgsrv.com:8835" //Localhost url as fallback
}