import { useContext } from 'react';
import classes from './JsonEditButton.module.css';
import { ModalContext } from 'constants/Contexts';
import Button from 'components/Button';
import JsonEditModal from 'components/Modals/JsonEditModal';

function JsonEditButton(props) {
    const { date, gameData, updateJson, rewindData } = props;

    const { setModalContent } = useContext(ModalContext);
    function updateGame(updatedGame) {
        const gameIndex = rewindData?.Games?.findIndex(game => game.Id === gameData.Id);
        const newJson = {
            ...rewindData,
            Games: rewindData?.Games.map((game, i) => {
                if (gameIndex === i) return JSON.parse(updatedGame)
                return game
            })
        };
        updateJson(newJson);
    }

    const openJsonEditModal = () => {
        setModalContent({
            title: `Edit Json - ${gameData?.Home.Alias} vs ${gameData?.Away.Alias} - ${date.toDateString()}`,
            body: <JsonEditModal
                json={gameData}
                updateJson={updateGame}
                setModalContent={setModalContent}
            />
        });
    }

    return (
        <div className={classes.root}>
            <Button
                className={classes.button}
                onClick={openJsonEditModal}
                text={`Edit Game Json`}
            />
        </div>
    );
}

export default JsonEditButton;