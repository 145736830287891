import RewardLink from 'components/Table/RewardLink';
export default function buildPackTable(Store, hasSku = false) {

    //TODO: Get enum from backend
    //TODO: Move this enum to constant for use elsewhere
    const storeType = {
        0: "Main Menu",
        1: "Playmakers",
        2: "Season",
        3: "Login Promotion",
        4: "LTERush",
        99: "BPForward",
        100: "BPProgression",
        101: "BPCollectibles",
        501: "Road to Fame",
        502: "Giants",
        999: "SKU Store"
    };

    const packTableHeaders = [
        { label: "Title", type: "string", value: "Title" },
        { label: "Pack ID", type: "number", value: "PackID", sortBy: "id" },
        { value: "id", type: "number", isSort: true},
        { label: "Analytic Name", type: "string", value: "AnalyticName" },
        { label: "Store", type: "number", value: "Store", sortBy: "storeValue" },
        {
            value: "storeValue",
            type: "number",
            isSort: true
        },
    ];

    if (hasSku) {
        packTableHeaders.push({ label: "SKU", type: "string", value: "SKU" });
        packTableHeaders.push({ label: "Price", type: "string", value: "Price" });
    }

    const { StoreId } = Store;
    const storeRows = Store?.Packs?.map(pack => {
        const { Id, AnalyticName, BasePackData } = pack;
        const title = BasePackData?.Display?.PackText.find(text => text.Id === "TITLE")

        const newPack = {
            id: Id,
            Title: title ? title.Value : 'N/A',
            PackID: <RewardLink rewardId={Id} store={StoreId} />,
            AnalyticName: AnalyticName,
            Store: `(${StoreId}) ${storeType[StoreId]}`,
            storeValue: StoreId
        };

        if (hasSku) {
            const { SKU, SKUCost } = BasePackData.Cost;
            newPack.SKU = SKU;
            newPack.Price = SKUCost;
        }

        return newPack;
    });
    return [packTableHeaders, storeRows];
}
