import getUrl from 'util/getUrl';
import hasValue from 'util/hasValue';
import fetcher from 'util/fetcher';

export default async (fields) => {
    const res = await fetcher(`${getUrl()}/UserInfo/GetLostUsers`, {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: {
            "Content-Type": "application/json",
        }
    });
    const data = await res.json();
    if (data.err) return [{err: `Error: ${data.err}`}]
    const users = Object.values(data.usersList);
    let newData = [];
    if (users?.length > 0) {
        newData = users.sort((a, b) => b.Score - a.Score).map(doc => {
            if (hasValue(doc?.CDID)) {
                return {
                    value: doc?.CDID,
                    label: doc?.CDID,
                }
            }
        });
    }
    return newData;
}