import { useRef } from 'react';
import classes from './JsonEditModal.module.css';
import Button from 'components/Button';

export default function JsonEditModal(props) {
    const { json, updateJson, setModalContent } = props;
    const textRef = useRef();

    return (
        <div className={classes.root}>
            <textarea
                spellcheck="false"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                defaultValue={JSON.stringify(json, undefined, 4)}
                ref={textRef}
            />
            <div className={classes.actionsWrapper}>
                <Button
                    onClick={() => setModalContent(null)}
                    text={"Cancel"}
                />
                <Button
                    onClick={() => {
                        updateJson(textRef.current.value);
                        setModalContent(null);
                    }}
                    text={"Save Changes"}
                />
            </div>
        </div>
    );
}
