export default function formatLogOptions(logOptions, currencyDefinitions, qualityDefinitions) {
    const logData = [];
    logOptions.forEach((log) => {
        switch (log?.Special) {
            case 2: {
                currencyDefinitions?.Currencies?.forEach(currency => {
                    logData.push({
                        Special: 2,
                        Add: true,
                        CurrencyType: currency.CurrencyType,
                        value: `${log.LogType}-${currency.CurrencyType}-add`,
                        label: log.LogType + ` [${currency.CurrencyName} Add]`
                    });
                    logData.push({
                        Special: 2,
                        Add: false,
                        CurrencyType: currency.CurrencyType,
                        value: `${log.LogType}-${currency.CurrencyType}-remove`,
                        label: log.LogType + ` [${currency.CurrencyName} Remove]`
                    });
                });
                break;
            }
            case 4: {
                qualityDefinitions?.QualityList?.forEach(quality => {
                    logData.push({
                        Special: 4,
                        Quality: quality.ID,
                        value: `${log.LogType}-${quality.ID}`,
                        label: log.LogType + ` [${quality.NameStr}]`
                    });
                });
                break;
            }
            default: {
                logData.push({
                    value: log.LogType,
                    label: log.LogType,
                });
            }
        }
    });
    return logData;
}