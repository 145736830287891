import classes from './Pagination.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function PaginationButton(props) {
    const { content, onClick, disabled = false } = props;

    return (
        <div
            className={`${classes.button} ${disabled ? classes.disabled : ''}`}
            onClick={!disabled ? onClick : null}
        >
            {content}
        </div>
    );
}

function TablePagination(props) {
    const { pagination, data } = props;
    const { page, size  } = pagination?.state;

    const lowerBound = data?.length > 0 ? (page * size) + 1 : 0;
    const upperBound = Math.min((page + 1) * size, data.length);
    const totalPages = Math.ceil(data.length / size);

    const getMiddlePagination = () => {
        let position = 0; //0 - start, 1 - mid - 2, end
        const lastPageIndex = totalPages - 1;
        //Start showing pages 2 - 4, if avaliable
        let minPage = 1; //Page 2
        let maxPage = 3; //Page 4
        //If less than 5 pages, mid should show all avaliable up to 1 less than max index
        if (totalPages <= 5) maxPage = lastPageIndex - 1;
        else if (totalPages > 5) { //More than 5 pages
            maxPage = 4;
            if (page >= 4 && page < lastPageIndex - 3) { // Position 1, in middle
                position = 1;
                minPage = page - 1;
                maxPage = page + 1;
            } else if (page >= lastPageIndex - 4) { //Position 2, at end
                position = 2;
                minPage = lastPageIndex - 4; //Always show last 5 pages
                maxPage = lastPageIndex - 1;
            }
        } else { //Exactly 5
            maxPage = 5;
        }
        const pages = [];
        for (let i = minPage; i < maxPage + 1; i++) {
            pages.push(i);
        }

        return (
            <>
                {position === 0 &&
                    pages.map(item => {
                        return <PaginationButton
                            key={`page-${item}`}
                            onClick={() => pagination.fns.onSetPage(item)}
                            content={item + 1}
                            disabled={page === item}
                        />
                    })
                }
                {totalPages >= 5 && position !== 0 && `…`}
                {position === 1 &&
                    pages.map(item => {
                        return <PaginationButton
                            key={`page-${item}`}
                            onClick={() => pagination.fns.onSetPage(item)}
                            content={item + 1}
                            disabled={page === item}
                        />
                    })
                }
                {totalPages > 5 && position !== 2 && `…`}
                {position === 2 && pages.map(item => {
                    return (
                        <PaginationButton
                            key={`page-${item}`}
                            onClick={() => pagination.fns.onSetPage(item)}
                            content={item + 1}
                            disabled={page === item}
                        />
                    )
                }) }
            </>
        )
    }

    return (
        <div
            className={classes.root}
        >
            <div className={classes.total}>
                {`Showing ${lowerBound} - ${upperBound} of ${data.length} entries`}
            </div>

            <div className={classes.paginationWrapper}>
                <PaginationButton
                    onClick={() => pagination.fns.onSetPage(page - 1)}
                    content={<FontAwesomeIcon icon={faChevronLeft} />}
                    disabled={page === 0}
                />
                <PaginationButton
                    onClick={() => pagination.fns.onSetPage(0)}
                    content={1}
                    disabled={page === 0}
                />
                {totalPages > 1 &&
                    getMiddlePagination()
                }
                {totalPages > 1 && <PaginationButton
                    onClick={() => pagination.fns.onSetPage(totalPages - 1)}
                    content={totalPages}
                    disabled={page === totalPages - 1}
                />}
                <PaginationButton
                    onClick={() => pagination.fns.onSetPage(page + 1)}
                    content={<FontAwesomeIcon icon={faChevronRight} />}
                    disabled={page === totalPages - 1 || totalPages === 0}
                />
            </div>
        </div>
    )
}

export default TablePagination;