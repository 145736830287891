import React, { useEffect, useState, useMemo } from 'react';
import classes from "../EventCalendar.module.css";

function EventTypeFilter(props) {
    const {
        eventFilter,
        gameFilter,
        gameCallback,
        eventCallback
    } = props;
  return (
    <div>
        <label>Game:</label>
        <div>
              <label className={classes.radio}><input type="radio" name="game" checked={gameFilter === "wwe"} onChange={(e) => gameCallback(e.target.checked, "wwe")} /> WWE</label>
              <label className={classes.radio}><input type="radio" name="game" checked={gameFilter === "splash"} onChange={(e) => gameCallback(e.target.checked, "splash")} /> Splash</label>
              <label className={classes.radio}><input type="radio" name="game" checked={gameFilter === "phoenix"} onChange={(e) => gameCallback(e.target.checked, "phoenix")} /> Phoenix</label>
              <label className={classes.radio}><input type="radio" name="game" checked={gameFilter === ""} onChange={(e) => gameCallback(e.target.checked, "")} /> All</label>
        </div>
        <label>Filters:</label>
        <div>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.event_color} checked={eventFilter.includes("GAME_EVENT")} onChange={(e) => eventCallback(e.target.checked, "GAME_EVENT")} /> Game Event</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.json_color} checked={eventFilter.includes("JSON_CHANGE")} onChange={(e) => eventCallback(e.target.checked, "JSON_CHANGE")} /> Json Changes</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.build_color} checked={eventFilter.includes("BUILD")} onChange={(e) => eventCallback(e.target.checked, "BUILD")} /> Builds</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.push_color} checked={eventFilter.includes("PUSH")} onChange={(e) => eventCallback(e.target.checked, "PUSH")} /> Push Notifications</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.maintenance_color} checked={eventFilter.includes("MAINTENANCE")} onChange={(e) => eventCallback(e.target.checked, "MAINTENANCE")} /> Maintenance and Updates</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.bullet_color} checked={eventFilter.includes("GAME_BULLET")} onChange={(e) => eventCallback(e.target.checked, "GAME_BULLET")} /> Bullets</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.twitch_color} checked={eventFilter.includes("TWITCH_DROPS")} onChange={(e) => eventCallback(e.target.checked, "TWITCH_DROPS")} /> Twitch Drops</label>
              <label className={classes.radio}><input type="checkbox" name="filter" className={classes.custom_color} checked={eventFilter.includes("CUSTOM")} onChange={(e) => eventCallback(e.target.checked, "CUSTOM")} /> Custom</label>
        </div>
    </div>
  )
}
export default EventTypeFilter;