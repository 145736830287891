import RewardLink from 'components/Table/RewardLink';
export default function buildCollectiblesRewardsTable(collectibleSettings, currencySettings) {

    const collectibleHeaders = [
        {
            value: 'id',
            label: 'Index',
            type: 'number',
            defaultSort: true
        },
        {
            value: 'pack',
            label: 'Pack',
            type: 'string'
        },
        {
            value: 'name',
            label: 'Name',
            type: 'string'
        },
        {
            value: 'damage',
            label: 'Damage',
            type: 'number'
        },
        {
            value: 'pathType',
            label: 'Path Type',
            type: 'number'
        },

    ];

    const innerHeaders = [
        {
            value: "currencyType",
            label: "Currency",
            type: "string",
        },
        {
            value: "cost",
            label: "Cost",
            type: "number"
        },

    ];


    let data = []

    function pushToData(item, data) {
        const { Index, PackID, PathType, CurrencyTypes, CurrencyCosts } = item;
        const nodes = [];
        item.CurrencyTypes.forEach((currency, i) => {
            nodes.push({
                currencyType: `${currencySettings.Currencies.find(curr => curr.CurrencyType === currency).CurrencyName} (${currency})`,
                cost: item.CurrencyCosts[i]
            });
        });
        data.push({
            id: Index,
            pack: <RewardLink rewardId={PackID} store={101} />,
            name: item?.Name ?? 'N/A',
            damage: item?.Damage ?? 'N/A',
            pathType: PathType,
            nodes
        });
    }

    if (collectibleSettings && currencySettings) {
        collectibleSettings.RegularCollectibles.forEach(item => pushToData(item, data));
        collectibleSettings.BossCollectibles.forEach(item => pushToData(item, data));
    }
    return [data, collectibleHeaders, innerHeaders];
}