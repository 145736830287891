import { objectiveType } from 'constants/NBARewindTypes';
export default function buildObjectivesTable(objectives, showGame = false) {
    const headers = [
        {
            value: "index",
            label: "Index",
            type: "number",
            defaultSort: true
        },
        {
            value: "player",
            label: "Player",
            type: "string",
        },
        {
            value: "type",
            label: "Type",
            type: "string",
        },
        {
            value: "value",
            label: "Value",
            type: "number",
        },
        ...showGame ? [{
            value: "game",
            label: "Game",
            type: "string",
        }] : [],
    ];

    const data = objectives?.map((objective, i) => {
        const { FeaturedPlayer, Type, Value, Weight } = objective;
        return {
            index: i,
            player: FeaturedPlayer?.FirstName ? `${FeaturedPlayer?.FirstName} ${FeaturedPlayer?.LastName}` : 'N/A',
            type: `${objectiveType?.[Type] ?? 'N/A'} (${Type})`,
            value: Value,
            game: objective?.game
        }
    });

    return [headers, data];
}