import classes from './RewardModal.module.css';
import useFileData from 'util/useFileData';
import buildRewardTable from 'util/buildRewardTable';
import buildCardTable from 'util/buildCardTable';
import Table from 'components/Table';
import Loader from 'components/Loader';

const storeTitle = {
    "0": "Main Menu Store (0)",
    "1": "Playmakers Store (1)",
    "2": "Season Pack Store (2)",
    "3": "Login Promotion Store (3)",
    "4": "Rush Pack Store (4)",
    "99": "BP Forward Pack Store (99)",
    "100": "BP Progression Store (100)",
    "501": "Road to Fame Pack Store (501)",
    "502": "Giants Pack Store (502)",
    "999": "Hard Currency Store (999)",
    "1000": "D2C Store (1000)"
}

export default function RewardModal(props) {
    const { rewardId, store } = props;

    const { data: mainMenuPackStore, isLoading: isMainLoading } = useFileData('Game/MainMenuPackSettings.json');
    const { data: seasonPackStore, isLoading: isSeasonLoading } = useFileData('Game/SeasonsPackSettings.json');
    const { data: playmakersPackStore, isLoading: isPlaymakersLoading } = useFileData('Game/PlaymakersPackSettings.json');
    const { data: loginPromotionStore, isLoading: isLoginPromotionLoading } = useFileData('Game/LoginPromotionPackSettings.json');
    const { data: rushPackStore, isLoading: isRushLoading } = useFileData('Game/LTERushPackSettings.json');
    const { data: roadPackStore, isLoading: isRoadLoading } = useFileData('Game/LTERoadToFamePackSettings.json');
    const { data: giantsPackStore, isLoading: isGiantsLoading } = useFileData('Game/LTEGiantsPackSettings.json');
    const { data: forwardPackStore, isLoading: isForwardLoading } = useFileData('Game/BPForwardPackSettings.json');
    const { data: bpProgressionPackStore, isLoading: isProgressionLoading } = useFileData('Game/BPProgressionPackSettings.json');
    const { data: bpCollectiblesPackStore, isLoading: isCollectiblesLoading } = useFileData('Game/BPCollectiblesPackSettings.json');
    const { data: hardCurrencyOffersPackStore, isLoading: isHardCurrencyLoading } = useFileData('Game/HardCurrencyPackSettings.json');
    const { data: d2cStore, isLoading: isd2cLoading } = useFileData('Game/DirectToConsumerPackSettings.json');

    const { data: cardDefinitionsData, isLoading: isCardLoading } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: currencyDefinitionsData, isLoading: isCurrencyLoading } = useFileData('Game/CurrencyDefinitionSettings.json');
    const { data: equipmentDefinitions, isLoading: isEquipLoading } = useFileData('Game/EquipmentDefinitions_Season1.json');
    const { data: qualitySettingsData, isLoading: isQualityLoading } = useFileData('Game/QualitySettings.json');
    const { data: playDefinitions, isLoading: isPlayLoading } = useFileData('Game/PlayCardDefinitions_Season1.json');

    const isLoading = (isMainLoading || isSeasonLoading || isLoginPromotionLoading || isPlaymakersLoading || isRushLoading || isForwardLoading || isProgressionLoading || isCollectiblesLoading || isHardCurrencyLoading || isd2cLoading || isRoadLoading || isCardLoading || isEquipLoading || isQualityLoading || isPlayLoading || isCurrencyLoading);

    //Loader while table loading
    if (isLoading) return <Loader />;

    //TODO: Move this check into separate util, avoid rewriting this/load statement for all packs loading
    const rewardsList = store === 0 ? mainMenuPackStore.Packs :
            store === 1 ? playmakersPackStore.Packs :
                store === 2 ? seasonPackStore.Packs :
                    store === 3 ? loginPromotionStore.Packs :
                        store === 4 ? rushPackStore.Packs :
                                store === 99 ? forwardPackStore.Packs :
                                    store === 100 ? bpProgressionPackStore.Packs :
                                        store === 101 ? bpCollectiblesPackStore.Packs :
                                        store === 501 ? roadPackStore.Packs :
                                        store === 502 ? giantsPackStore.Packs :
                                        store === 999 ? hardCurrencyOffersPackStore.Packs :
                                            store === 1000 ? d2cStore.Packs : null

    const reward = rewardsList?.find(r => r.Id === rewardId);

    //Wait to build until after loaded
    const [headers, innerHeaders, data] = buildRewardTable(reward, cardDefinitionsData, currencyDefinitionsData, equipmentDefinitions, qualitySettingsData, playDefinitions);

    return (
        <>
            <h6 className={classes.header }>{`${reward.AnalyticName ? reward.AnalyticName + " - " : ""}Pack ${rewardId} from ${storeTitle?.[store] ?? store}`}</h6>
            <Table
                columns={headers}
                innerColumns={innerHeaders}
                data={data}
                hasChildren
                startExpanded
            />
        </>
    );
}

export function CardModal(props) {
    //TODO: Allow for list of ids
    const { id } = props;

    const { data: cardDefinitionsData, isLoading: cardLoading } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: qualitySettingsData, isLoading: qualityLoading } = useFileData('Game/QualitySettings.json');
    const { data: cardStatSettings, isLoading: statLoading } = useFileData('Game/CardStatDefinitionSettings.json');
    const { data: teamSettings, isLoading: teamLoading } = useFileData('Game/NFLTeamDefinitionSettings.json');

    if (cardLoading || qualityLoading || statLoading || teamLoading) {
        return (
            <Loader />
        );
    }
    
    const [headers, data] = buildCardTable([
        id
    ], cardDefinitionsData, qualitySettingsData, cardStatSettings, teamSettings);

    return (
        <Table
            columns={headers}
            data={data}
        />
    );
}