import { useState, useEffect } from 'react';
import classes from './Table.module.css';
import { Table as ReactTable, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort"
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { usePagination } from "@table-library/react-table-library/pagination";
import TextInput from 'components/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import pageSizeOptions from 'constants/pageSizeOptions';
import Select from 'components/Select';
import TablePagination from './Table/Pagination';
import Button from 'components/Button';

function Table(props) {

    const {
        data,
        columns,
        innerColumns,
        hasChildren = false,
        childFilterText = "",
        styles,
        onClick = null,
        showActions = true,
        hideDefaultActions = false,
        showPagination = true,
        customActions = [],
        startExpanded = false,
    } = props;
    //TODO: Add prop for selected row - highlight currently selected row when prop exists

    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(startExpanded ? data.map(item => item.id) : []);
    const [perPage, setPerPage] = useState(pageSizeOptions[0]);
    const [onlyChildren, setOnlyChildren] = useState(false);

    const allOpen = open?.length === data?.length && open?.length > 0;

    const handleExpand = item => {
        const newId = parseInt(item.currentTarget.id.split('-')[0]);
        if (open.includes(newId)) {
            setOpen(prev => prev.filter(id => id !== newId))
        } else setOpen(prev => prev.concat(newId));
    }

    const expandAll = () => {
        if (!allOpen) setOpen(data.map(item => item.id));
        else setOpen([]);
    }

    const search = (item, row) => {
        const type = typeof item;
        if (type === "string") {
            return item?.toLowerCase().includes(query.toLowerCase());
        } else if (type === "number") {
            return item.toString().includes(query.toLowerCase());
        } else if (Array.isArray(item)) {
            //TODO: This will not filter the child items. Should child items themselves be filtered, or show all items if row is in range?
            const hasChild = item.some(child => Object.values(child).some(childItem => search(childItem, row)));
            //Side effect to set child row to expanded
            if (hasChild && !open.includes(row?.id)) setOpen(prev => prev.concat(row.id)); //Could add plural of same row if open, but will filter out when closing 
            if (!hasChild && open.includes(row?.id)) setOpen(prev => prev.filter(id => id !== row.id));
            return hasChild
        }
    }

    //TODO: Why was first column being sliced off of search? Add field to ignore search if we want to avoid searching first row
    const searchedItems = data?.filter((row) => Object.values(row)?.some(item =>
        search(item, row))
    )?.filter((row) => onlyChildren ? row?.nodes?.length > 0 : true); //Second filter is showing/hiding children options
    const newData = { nodes: searchedItems };

    //TODO: Different sort based on type
    const sortFns = {}
    columns.forEach(item => {
        let column = item;
        let sortColumn = column;
        if (column?.sortBy) {
            sortColumn = columns.find(newColumn => newColumn.value === item.sortBy);
        }
        const type = sortColumn.type
        if (type === 'number') sortFns[column.value] = (array) => array.toSorted((a, b) => a[sortColumn.value] - b[sortColumn.value]);
        else sortFns[column.value] = (array) => array.toSorted((a, b) => {
            if (a[sortColumn.value] < b[sortColumn.value]) return -1;
            if (a[sortColumn.value] > b[sortColumn.value]) return 1;
            return 0;
        })
    })

    const defaultSort = columns.find(col => col.defaultSort) ?? columns[0];

    const sort = useSort(newData, {
        state: {
            sortKey: defaultSort?.sortBy ?? defaultSort.value,
            reverse: defaultSort?.defaultReverse ?? false,
        },
        onChange: onSortChange,
    }, {
        sortIcon: {
                margin: '4px',
            iconUp: <div className={classes.chevrons}>
                    <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={"grow-4"}
                    />
                    <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={"shrink-2"}
                    />
                </div>,
            iconDown: <div className={classes.chevrons}>
                    <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={"shrink-2"}
                    />
                    <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={"grow-4"}
                    />
                </div>,
            iconDefault: <div className={classes.chevrons}>
                    <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={"shrink-2"}
                    />
                    <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={"shrink-2"}
                    />
                </div>
        },
        sortFns
    });

    function onPaginationChange(action, state) {
    }

    const pagination = usePagination(
        newData,
        {
            state: {
                page: 0,
                size: perPage.value
            },
            onChange: onPaginationChange,
        }
    );
    function onSortChange(action, state) {
        pagination.fns.onSetPage(0)
    }

    const handleSearch = e => {
        setQuery(e.target.value);
        if (e.target.value === "") setOpen([]); //Collapse open rows if search is cleared
        pagination.fns.onSetPage(0);
    }

    const { maxHeight } = styles || {};

    const visibleColumns = columns.filter(column => !column.isSort);

    //TODO: Changing pages causes some gaps between headers - Find more consistent styling answer
    const theme = useTheme({
        Table: `
            grid-template-columns: ${hasChildren ? '4rem' : ''} repeat(${visibleColumns?.length}, minmax(auto, 1fr));
            max-height: ${maxHeight ? maxHeight : "auto"};
        `, 
        ...(onClick && {
            Row: `
            cursor: pointer;
            &:hover .td {
                background-color: var(--table-stroke);
            }
        `}),
    });

    useEffect(() => {
        pagination.fns.onSetPage(0)
    }, [data]);

    //TODO: Make row key and inner row key unique per table - currently overlap if multiple tables are drawn
    return (
        <div className={classes.tableWrapper}>
            {showActions && <div className={classes.tableActions}>
                <div className={classes.leftActions}>
                    {!hideDefaultActions && <label className={classes.selectWrapper}>
                        {`Page Size`}
                        <Select
                            className={classes.pageOption}
                            options={pageSizeOptions}
                            onChange={val => setPerPage(val)}
                            value={perPage}
                            index={2}
                        />
                    </label>}
                    {hasChildren && <Button
                        className={classes.expandAll}
                        onClick={expandAll}
                        text={allOpen ? "Collapse All" : "Expand All"}
                    />}
                    {childFilterText &&
                        <label
                            className={classes.onlyChildrenLabel}
                        >{childFilterText}
                            <input
                                type="checkbox"
                                checked={onlyChildren}
                                value={onlyChildren}
                                onChange={e => {
                                    setOnlyChildren(prev => !prev);
                                    pagination.fns.onSetPage(0)
                                }}
                            ></input>
                        </label>
                    }
                    {customActions?.length > 0 &&
                        customActions?.map(custom => custom)
                    }
                </div>
                {!hideDefaultActions && <TextInput
                    label={"Search"}
                    value={query}
                    onChange={handleSearch}
                />}
            </div>}
            <ReactTable
                data={newData}
                sort={sort}
                theme={theme}
                pagination={pagination}
                className={classes.table}
            >
                {(tableList) => (
                    <>
                    <Header>
                        <HeaderRow>
                            {hasChildren &&
                                <HeaderCell
                                        key={`column-expand`}
                                        id={`tablecolumn-expand`}
                                        className={classes.expandHeader}
                                >
                                    {""}
                                </HeaderCell>
                            }
                                {visibleColumns.map(column => column?.noSort ?
                                    (
                                        <HeaderCell
                                            key={`column-${column.value}`}
                                            id={`tablecolumn-${column.value}`}
                                            className={classes.header}
                                        >
                                            {column.label}
                                        </HeaderCell>
                                    )
                                    : (
                                <HeaderCellSort
                                    key={`column-${column.value}`}
                                    id={`tablecolumn-${column.value}`}
                                    sortKey={column.value}
                                    className={classes.header}
                                >
                                    {column.label}
                                </HeaderCellSort>
                            ))}
                        </HeaderRow>
                    </Header>
                    <Body>
                        {tableList.map(row => (
                            <>
                            <Row
                                key={row?.id}
                                item={row}
                                className={classes.row}
                                onClick={onClick ? row => onClick(row) : null}
                            >
                                    {hasChildren &&
                                        (row?.nodes?.length > 0 ? 
                                        <Cell
                                            id={`${row?.id}-expand`}
                                            key={`${row?.id}-expand`}
                                            className={classes.expand}
                                            onClick={handleExpand}
                                        >
                                            <div className={classes.expandContent}>
                                                <div>{`(${row.nodes.length})`}</div>
                                                {open?.includes(row?.id) ?
                                                    <FontAwesomeIcon
                                                        icon={faChevronDown}
                                                    /> : 
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                    />
                                                }
                                            </div>
                                        </Cell>
                                        :
                                    <Cell key={`${row?.id}-expand`} className={classes.emptyExpand}></Cell>
                                    )
                                }
                                {visibleColumns.map(column => {
                                    const item = row[column.value];
                                    const isText = typeof item === "string" || typeof item === "number";
                                    return (
                                        <Cell
                                            key={`${row?.id}-${column.value}`}
                                            className={classes.cell}
                                        >
                                            {isText ? <span title={item}>{item}</span> : item}
                                        </Cell>
                                    );
                                })}
                            </Row>
                            {row?.nodes?.length > 0 && open.includes(row.id) &&
                                <tr
                                    className={classes.inner}
                                >
                                <td
                                    className={classes.innerData}
                                >
                                <ReactTable
                                    key={`${row?.id}-inner`}
                                    data={{ nodes: row.nodes }}
                                    className={classes.innerTable}
                                >
                                    {(innerTableList) => (
                                    <>
                                    <Header>
                                        <HeaderRow>
                                                {innerColumns.map(innerHeader => (
                                                    <HeaderCell
                                                        key={`${row?.id}-innercolumn-${innerHeader.value}`}
                                                        id={`${row?.id}-innercolumn-${innerHeader.value}`}
                                                        className={classes.innerHeader}
                                                    >
                                                        {innerHeader.label}
                                                    </HeaderCell>
                                                ))}
                                        </HeaderRow>
                                    </Header>
                                    <Body>
                                        {row.nodes.map(node => (
                                            <Row
                                                key={`${row?.id}-node-${node.id}`}
                                                item={node}
                                                className={classes.innerRow}
                                            >
                                                {innerColumns.map(nodeKey => (
                                                    <Cell
                                                        key={`${row?.id}-node-${node.id}-${nodeKey.value}`}
                                                        className={classes.innerCell}
                                                    >
                                                        <span title={node[nodeKey.value]}>{node[nodeKey.value]}</span>
                                                    </Cell>
                                                ))}
                                            </Row>
                                        ))}
                                    </Body>
                                    </>
                                    )}
                                </ReactTable>
                                </td>
                                </tr>
                            }
                            </>
                        ))}
                    </Body>
                    </>
                )}
            </ReactTable>
            {showPagination && <TablePagination pagination={pagination} data={newData?.nodes} />}
        </div>
    );
}

export default Table;
