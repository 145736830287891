import classes from './RewardLink.module.css';
import { useContext } from 'react';
import { ModalContext } from 'constants/Contexts';
import RewardModal, { CardModal } from 'components/Modals/RewardModal';
import useFileData from 'util/useFileData';

export default function RewardLink(props) {
    const { rewardId, store } = props;

    const { data: mainMenuPackStore, isLoading: isMainLoading } = useFileData('Game/MainMenuPackSettings.json');
    const { data: seasonPackStore, isLoading: isSeasonLoading } = useFileData('Game/SeasonsPackSettings.json');
    const { data: playmakersPackStore, isLoading: isPlaymakersLoading } = useFileData('Game/PlaymakersPackSettings.json');
    const { data: loginPromotionStore, isLoading: isLoginPromotionLoading } = useFileData('Game/LoginPromotionPackSettings.json');
    const { data: rushPackStore, isLoading: isRushLoading } = useFileData('Game/LTERushPackSettings.json');
    const { data: roadPackStore, isLoading: isRoadLoading } = useFileData('Game/LTERoadToFamePackSettings.json');
    const { data: giantsPackStore, isLoading: isGiantsLoading } = useFileData('Game/LTEGiantsPackSettings.json');
    const { data: forwardPackStore, isLoading: isForwardLoading } = useFileData('Game/BPForwardPackSettings.json');
    const { data: bpProgressionPackStore, isLoading: isProgressionLoading } = useFileData('Game/BPProgressionPackSettings.json');
    const { data: bpCollectiblesPackStore, isLoading: isCollectiblesLoading } = useFileData('Game/BPCollectiblesPackSettings.json');
    const { data: hardCurrencyOffersPackStore, isLoading: isHardCurrencyLoading } = useFileData('Game/HardCurrencyPackSettings.json');
    const { data: d2cStore, isLoading: isd2cLoading } = useFileData('Game/DirectToConsumerPackSettings.json');

    const isLoading = (isMainLoading || isSeasonLoading || isLoginPromotionLoading || isPlaymakersLoading || isRushLoading || isForwardLoading || isProgressionLoading || isCollectiblesLoading || isHardCurrencyLoading || isd2cLoading || isRoadLoading || isGiantsLoading);

    const rewardsList = isLoading ? null :
        store === 0 ? mainMenuPackStore.Packs :
            store === 1 ? playmakersPackStore.Packs :
                store === 2 ? seasonPackStore.Packs :
                    store === 3 ? loginPromotionStore.Packs :
                        store === 4 ? rushPackStore.Packs :
                            store === 501 ? roadPackStore.Packs :
                                store === 99 ? forwardPackStore.Packs :
                                    store === 100 ? bpProgressionPackStore.Packs :
                                        store === 101 ? bpCollectiblesPackStore.Packs :
                                        store === 502 ? giantsPackStore.Packs :
                                        store === 999 ? hardCurrencyOffersPackStore.Packs :
                                            store === 1000 ? d2cStore.Packs : null

    const reward = rewardsList?.find(r => r.Id === rewardId);

    const { setModalContent } = useContext(ModalContext);
    return (
        <div
            className={classes.link}
            onClick={() => setModalContent({
                title: "Rewards",
                body: <RewardModal rewardId={rewardId} store={store} />
            })}
        >
            {`${reward?.AnalyticName ? reward.AnalyticName + " " : ""}(${rewardId})`}
        </div>
    );
};

export function CardLink(props) {
    const { id } = props;
    const { data: cardDefinitionsData } = useFileData('Game/CardDefinitions_Season1.json');

    const card = cardDefinitionsData?.CardList.find(item => item.ID === id);

    const { setModalContent } = useContext(ModalContext);
    return (
        <div
            className={classes.link}
            onClick={() => setModalContent({
                title: "Card Details",
                body: <CardModal id={id} />
            })}
        >
            {`${card?.FirstName} ${card?.LastName} (${id})`}
        </div>
    );
}