import { useState, useEffect } from 'react';
import classes from './SelectedEvent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import PlayerSearch from '../UserInfo/PlayerSearch';
import Table from 'components/Table';
import useFileData from 'util/useFileData';
import buildPlaymakerRewardsTable from 'util/buildEventManagerTables/buildPlaymakerRewardsTable';
import buildEventsTable from 'util/buildEventManagerTables/buildEventsTable';
import Tabs from 'components/Tabs/Tabs';
import Accordian from 'components/Accordian';
import usePlaymakersData from 'util/usePlaymakersData';
import useLTEData from 'util/useLTEData';
import EventDetail from './EventDetail';
import buildLTERewards from 'util/buildEventManagerTables/buildLTERewardsTable';
import buildBulletRewards from 'util/buildEventManagerTables/buildBulletRewardsTable';
import buildBPProgressionRewards from './BPProgressionTable';
import buildCollectiblesRewards from 'util/buildEventManagerTables/buildCollectiblesRewardsTable';
import hasValue from 'util/hasValue';
import RewardLink, { CardLink } from 'components/Table/RewardLink';

function SelectedEvent(props) {
    const { event, setSelectedEvent, userSearch } = props;

    const pointTabs = [
        { name: 'point', label: 'Point Rewards' },
        { name: 'leaderboard', label: 'Leaderboard Rewards' },
    ]

    const [rewardTab, setRewardTab] = useState(pointTabs[0].name); //point || leaderboard

    const { data: playmakersSettings } = useFileData('Game/PlaymakersSettings.json');
    const { data: playmakerUserData, isPlaymakersLoading, mutate: mutatePlaymakers } = usePlaymakersData(userSearch);
    const { data: rushSettings } = useFileData('Game/LTERushSettings.json');
    const { data: playForwardSettings } = useFileData('Game/BPForwardSettings.json');
    const { data: progressionSettings } = useFileData('Game/BPProgressionSettings.json');
    const { data: bpCollectiblesSettings } = useFileData('Game/BPCollectiblesSettings.json');
    const { data: roadToFameSettings } = useFileData('Game/LTERoadToFameSettings.json');
    const { data: giantsSettings } = useFileData('Game/LTEGiantsSettings.json');

    const { data: currencySettings } = useFileData('Game/CurrencyDefinitionSettings.json');

    //TODO: Map LTE type as enum in constants or from endpoint
    const { data: rushUserData, isLoading: isRushLoading, mutate: mutateRush } = useLTEData(userSearch, 0); //0 - Rush Type
    const { data: roadUserData, isLoading: isRoadLoading, mutate: mutateRoad } = useLTEData(userSearch, 2);
    const { data: giantsUserData, isLoading: isGiantsLoading, mutate: mutateGiants } = useLTEData(userSearch, 3);

    const current = event.event === "Playmakers"
        ? playmakersSettings
        : event.event === 'Playmakers Week'
            ? playmakersSettings.WeeklySchedule?.find(play => play.Week === event.id)
            : event.event === 'Endzone Rush'
                ? rushSettings
                : event.event === 'Play it Forward'
                    ? playForwardSettings
                    : event.event === "Progression Bullet Pack"
                        ? progressionSettings
                        : event.event === "Road to Glory" ? 
                            roadToFameSettings
                            : event.event === "Collectibles Bullet Pack" ? 
                            bpCollectiblesSettings
                                : event.event === "Giants" ? 
                            giantsSettings
                            : null; //On other events, search for specific settings by id

    const eventTitle = () => {
        //Indicate Playmakers parent id, then week 
        if (event.event === "Playmakers") {
            return ``;
        } else if (event.event === "Playmakers Week") {
            return `- Type: ${current?.WeekType}, Start: ${event.start}`;
        } 
        else return ""
    }

    function getLTESummary(summaries, eventData) {

        if (hasValue(eventData?.gnData?.EarnedPoints)) {
            summaries.push({
                label: "Points",
                value: eventData.gnData.EarnedPoints
            });
        }

        if (userSearch && !hasValue(eventData?.gnData)) {
            summaries.push({
                label: "",
                value: `No event history found for ${userSearch}`,
                type: "warning"
            })
        }

        const leaderboardPlayer = eventData?.leaderboard?.LocalPlayers?.LeaderboardEntries.find(player => player.Id === parseInt(userSearch));
        if (hasValue(leaderboardPlayer?.Rank)) {
            summaries.push({
                label: "Rank",
                value: leaderboardPlayer.Rank
            });
        }
    }

    //TODO: Show active week for playmakers - Use inWeek as state?
    const getEventSummary = () => {
        const newSummary = [];
        if (current?.ChaseCardId) {
            newSummary.push({
                label: "Chase Card",
                value: <CardLink id={current.ChaseCardId} />
            })
        } else if (current?.ChaseRewardPackID) { //Currently only for bullet packs
            //Store is not in progression settings file, so setting manually
            let packStore = null 
            if (event.event.toLowerCase().includes('progression')) packStore = 100;
            else if (event.event.toLowerCase().includes('collectibles')) packStore = 101;
            newSummary.push({
                label: "Chase Pack",
                value: <RewardLink rewardId={current.ChaseRewardPackID} store={packStore} />
            })
        }
        if (event.event === 'Playmakers') {
            if (current?.Week && current.Week === playmakerUserData?.gnData?.Rewards?.ActiveWeek) {
                newSummary.push({
                    label: "Active Week",
                    value: current.Week
                });
            }
        }
        if (event.event === 'Playmakers Week' || event.event === 'Playmakers') {
            if (hasValue(playmakerUserData?.gnData?.Rewards?.SeasonPoints)) {
                newSummary.push({
                    label: "Season Points",
                    value: playmakerUserData.gnData.Rewards.SeasonPoints
                });
            }
        }
        if (event.event === 'Playmakers Week') {
            if (current.Week === playmakerUserData?.gnData?.Rewards?.ActiveWeek) {
                if (hasValue(playmakerUserData?.gnData?.Rewards?.WeekPoints)) {
                    newSummary.push({
                        label: "Weekly Points",
                        value: playmakerUserData.gnData.Rewards.WeekPoints
                    });
                }
            }
        }
        if (event.event === 'Endzone Rush') getLTESummary(newSummary, rushUserData);
        if (event.event === 'Road to Glory') getLTESummary(newSummary, roadUserData);
        if (event.event === "Giants") getLTESummary(newSummary, giantsUserData); 
        return newSummary
    };

    const eventSummary = getEventSummary();
    
    let [pointRewards, leaderboardRewards, pointHeaders, leaderboardHeaders, pointInnerHeaders] = [null, null, null, null, null];
    let [eventHeaders, eventChildren] = [null, null];

    let showPlayerSearch = true;
    let isBullet = false;
    if (current) {
        if (event.event === 'Playmakers' || event.event === 'Playmakers Week') {
            let inWeek = false;
            if (event.event === 'Playmakers' && playmakersSettings?.WeeklySchedule) { //Set weekly events for playmakers children
                [eventHeaders, eventChildren] = buildEventsTable(null, playmakersSettings.WeeklySchedule);
            }
            if (event.event === 'Playmakers Week') { //Show player search only on current week
                const date = new Date();
                if (date > new Date(current.Schedule.Start) && date < new Date(current.Schedule.End)) {
                    showPlayerSearch = true
                    inWeek = true;
                }
                else showPlayerSearch = false;
            } else showPlayerSearch = true;
            //Set up tables w/ playmaker settings
            [pointRewards, leaderboardRewards, pointHeaders, leaderboardHeaders] = buildPlaymakerRewardsTable(current.Milestones.Rewards, current.Leaderboard.Rewards, inWeek ? playmakerUserData?.gnData.Rewards?.ClaimedWeeklyRewards : event.event === 'Playmakers' ? playmakerUserData?.gnData.Rewards?.ClaimedSeasonalRewards : [], userSearch?.length > 0 && inWeek);
        } //TODO: Add other event types
        //TODO: Genericise LTE call for lte types - use different identifier instead of explicit name
        if (event.event === 'Endzone Rush') {
            [pointRewards, leaderboardRewards, pointHeaders, leaderboardHeaders] = buildLTERewards(rushUserData?.gnData ? rushUserData.gnData.MilestoneRewards.Rewards : current.Milestones.Rewards, rushUserData?.gnData ? rushUserData?.gnData.LeaderboardRewards.Rewards : current.Leaderboards.Rewards, 4, userSearch?.length > 0 && rushUserData?.gnData);
        }
        if (event.event === 'Play it Forward') {
            [pointRewards, pointHeaders, pointInnerHeaders] = buildBulletRewards(current.Board, 99);
            isBullet = true;
        }
        if (event.event === 'Progression Bullet Pack') {
            [pointRewards, pointHeaders] = buildBPProgressionRewards(current.ProgressionLocations);
            isBullet = true;
        }
        if (event.event === "Road to Glory") {
            [pointRewards, leaderboardRewards, pointHeaders, leaderboardHeaders] = buildLTERewards(roadUserData?.gnData ? roadUserData.gnData.MilestoneRewards.Rewards : current.Milestones.Rewards, roadUserData?.gnData ? roadUserData?.gnData.LeaderboardRewards.Rewards : current.Leaderboards.Rewards, 501, userSearch?.length > 0 && roadUserData?.gnData);
        }
        if (event.event === "Giants") {
            [pointRewards, leaderboardRewards, pointHeaders, leaderboardHeaders] = buildLTERewards(giantsUserData?.gnData ? giantsUserData.gnData.MilestoneRewards.Rewards : current.Milestones.Rewards, giantsUserData?.gnData ? giantsUserData?.gnData.LeaderboardRewards.Rewards : current.Leaderboards.Rewards, 502, userSearch?.length > 0 && giantsUserData?.gnData);
        }
        if (event.event === "Collectibles Bullet Pack") {
            //TODO: Show purchasable packs? 
            [pointRewards, pointHeaders, pointInnerHeaders] = buildCollectiblesRewards(bpCollectiblesSettings, currencySettings);
        }
    }

    //Use to override display if no table exists for points, but leaderboard exists
    const tableDisplay = pointRewards?.length > 0 && leaderboardRewards?.length > 0 ? rewardTab
        : pointRewards?.length > 0 ? 'point' : 'leaderboard'; //leaderboard fallback is fine, as table won't display at all if both have no value

    return (
        <div
            className={classes.root}
        >
            <h4 className={classes.title}>
                {`${event.event}${event?.id && event.id !== 'N/A' ? ' ID: ' + event.id : ''} ${eventTitle()}`}
            </h4>
            {event.event === 'Playmakers' && 
                <Accordian
                    label={`Weekly Events (${eventChildren.length})`}
                >
                    <Table
                        columns={eventHeaders}
                        data={eventChildren}
                        styles={{ maxHeight: '30rem' }}
                        onClick={row => setSelectedEvent(row)}
                    />
                </Accordian>
            }
            {eventSummary.length > 0 && <div className={classes.summary}>
                {eventSummary.map(detail =>
                    <EventDetail key={detail.value} detail={detail} />
                )}
            </div>}
            {((pointRewards && pointRewards?.length > 0) || (leaderboardRewards && leaderboardRewards.length > 0))  && 
                <div className={classes.rewardsTable}>
                    {pointRewards?.length > 0 && leaderboardRewards?.length > 0 ? <Tabs
                        tabValues={pointTabs}
                        selectedTab={rewardTab}
                        setSelected={setRewardTab}
                    /> : !isBullet ?
                    <h5>{tableDisplay === "point" ? "Point Rewards" : "Leaderboard Rewards"}</h5> : null
                    }
                    <Table
                        columns={tableDisplay === 'point' ? pointHeaders : leaderboardHeaders}
                        data={tableDisplay === 'point' ? pointRewards : leaderboardRewards}
                        innerColumns={pointInnerHeaders ? pointInnerHeaders : null}
                        hasChildren={pointInnerHeaders ? true : false}
                        styles={{ maxHeight: event.event === "Playmakers" ? '15rem' : '30rem' }}
                    />
                </div>
            }
        </div>
    );

}

export default SelectedEvent;