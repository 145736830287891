import React, { useState } from 'react';
import classes from './Accordian.module.css';
import Loader from 'components/Loader';

function Accordian(props) {
    const { label, children, defaultOpen = false, isLoading = false, className = "" } = props;

    const [isOpen, setOpen] = useState(defaultOpen);

    return (
        <div className={classes.root}>
            <div
                className={`${classes.label} ${className}`}
                onClick={() => children ? setOpen(prev => !prev) : null}
            >
                {label}
            {isLoading ? <div className={classes.loader}><Loader /></div> : null}
            </div>
            {isOpen ? <div className={classes.children}>
                {children}
            </div> : null}
        </div>
    );
}
export default Accordian;